<template>
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <div class="page-container-table">
      <!-- <div class="list-operation">
        <el-button type="primary" size="small" @click="handleAdd">
          录入额度
        </el-button>
      </div> -->
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <!-- <template #status="{ row }">
          <span>{{ row.status === 0 ? '进行中' : '已下架' }}</span>
          <span class="reason">{{ row.reason === 1 ? '(售出下架)' : row.reason === 2 ? '(超时下架)' : row.reason === 3 ? '(主动下架)': '' }}</span>
        </template> -->
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
    <el-dialog title="额度调整" :visible.sync="dialogFormVisible" class="dialog" width="40%">
      <el-form ref="dialogRef" :model="dialogList" :rules="dialogRule" label-width="150px" @submit.native.prevent>
        <el-form-item label="供应商" prop="cmpName">
          <el-input v-model="dialogList.cmpName" disabled class="br-input" />
        </el-form-item>
        <el-form-item label="核心企业" prop="coreName">
          <el-input v-model="dialogList.coreName" disabled class="br-input" />
        </el-form-item>
        <el-form-item label="资金方" prop="bankName">
          <el-input v-model="dialogList.bankName" disabled class="br-input" />
        </el-form-item>
        <el-form-item label="供应商总额度(元)" prop="quotaSum">
          <el-input
            v-model="dialogList.quotaSum"
            class="br-input"
            clearable
            oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          />
        </el-form-item>
        <el-form-item label="供应商额度到期日" prop="quotaDue">
          <el-date-picker
            v-model="dialogList.quotaDue"
            type="date"
            placeholder="核心企业额度到期日"
            class="br-input"
            :picker-options="dateOptions"
            value-format="timestamp"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button class="br-btn template-reset-btn" @click="dialogFormVisible = false">
          取 消
        </el-button>
        <el-button class="br-btn template-search-btn" type="primary" @click="dialogSubmit('dialogRef')">
          确认提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
import { getSupplierList, editSupplierAmount } from '@/api/tradeFinancing.js'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      dialogFormVisible: false,
      loading: false,
      total: 0,
      formInline: {
        coreName: '', // 核心企业
        bankName: '', // 资金方
        quotaStat: '',
        pageSize: 10,
        pageNum: 1
      },
      listData: [],
      formItemArr: [
        { type: 'input', label: '供应商', value: 'cmpName' },
        { type: 'input', label: '核心企业', value: 'coreName' },
        { type: 'select', label: '资金方', value: 'bankName' },
        { type: 'input', label: '集团名称', value: 'groupCmpName' },
        { type: 'select', label: '额度状态', value: 'quotaStat', pLabel: 'dictName', pValue: 'dictId', child: [{ dictId: 0, dictName: '有效' }, { dictId: 1, dictName: '失效' }] }
      ],
      itemData: [
        { label: '供应商', prop: 'cmpName', width: 220 },
        { label: '资金方', prop: 'bankName', width: 120 },
        { label: '所属核心企业', prop: 'coreName', width: 280 },
        { label: '集团名称', prop: 'groupCmpName', width: 180 },
        { label: '总额度(元)', prop: 'quotaSum', width: 180 },
        { label: '冻结额度(元)', prop: 'quotaDisabled', width: 180 },
        { label: '可用额度(元)', prop: 'quotaAbled', width: 180 },
        { label: '额度到期日', prop: 'quotaDue', width: 180 },
        { label: '额度状态', prop: 'quotaStat', child: [{ dictName: '有效', dictId: 0 }, { dictName: '失效', dictId: 1 }], width: 180 }
      ],
      operationButton: [
        { bType: 'text', label: '额度调整', handleEvent: this.handleAmount }
      ],
      dialogList: {
        id: '',
        cmpName: '', // 供应商 编辑的时候不要
        coreName: '', // 核心企业 编辑的时候不要
        bankName: '', // 资金方 编辑的时候不要
        quotaDue: '', // 核心企业额度到期日
        quotaId: '', // 核心企业额度表id
        quotaSum: '' // 供应商总额度
      },
      dialogRule: {
        quotaDue: [{ required: true, message: '请选择核心企业额度到期日', trigger: 'blur' }],
        quotaSum: [{ required: true, message: '请输入供应商总额度', trigger: 'blur' }]
      },
      dateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      }
    }
  },
  methods: {
    getDate(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      getSupplierList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.list
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    handleAmount(row) {
      this.dialogFormVisible = true
      this.dialogList = row
    },
    dialogSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editSupplierAmount(this.dialogList, res => {
            this.dialogFormVisible = false
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '额度调整成功'
              })
              this.getDate()
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          }, () => {
            this.dialogFormVisible = false
          })
        }
      })
    }

  }
}
</script>
